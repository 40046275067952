















































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Banner',
  components: {
    // RenderCom: {
    //   props: {
    //     node: {}
    //   },
    //   render (): any {
    //     // noinspection TypeScriptUnresolvedVariable
    //     return this.$props.node as any;
    //   }
    // }
  }
})
export default class Banner extends Vue {
  private delayTime = 1000
  private imgWidth = 0 // 图片宽度
  private isMoving = false
  private autoSwitch = 0 // 自动轮播
  private movingEndTimer = 0;
  public imgs = new Array(2).fill(1).map((it, i) => require(`../../../assets/property/home_banner${i + 1}.png`))
  public imgsPhone = new Array(2).fill(1).map((it, i) => require(`../../../assets/property/home_phone_banner${i + 1}.png`))

  private currentIndex = 0
  get bannerList () {
    if (this.imgWidth < 700) {
      return [
        ...this.imgsPhone,
        this.imgsPhone[0]
      ].map((img, i) => ({
        index: i,
        img
      }));
    } else {
      return [
        ...this.imgs,
        this.imgs[0]
      ].map((img, i) => ({
        index: i,
        img
      }));
    }
  }

  get imgStyle () {
    return {
      transition: this.isMoving ? `all ${this.delayTime}ms` : '',
      width: this.bannerList.length * 100 + 'vw',
      transform: 'translateX(' + -this.currentIndex * 100 + 'vw)'
    };
  }

  public created () {
    this.$bus.$on('resize', this.getWindowWidth);
  }

  public mounted () {
    this.getWindowWidth();
    this.startAuto();
    // this.runInv();
  }

  // public beforeDestroy () {
  //   console.log('asdasd');
  //   if (this.timer) {
  //     clearInterval(this.timer);
  //   }
  // }

  public getWindowWidth () {
    this.imgWidth = window.innerWidth;
  }

  public startAuto () { // 鼠标离开，再次自动轮播
    clearInterval(this.autoSwitch);
    this.autoSwitch = setInterval(() => { // 自动切换
      this.startMoving();
    }, 5000);
  }

  public stopAuto () { // 鼠标移动，暂停自动轮播
    clearInterval(this.autoSwitch);
  }

  public startMoving (step = 1) {
    this.isMoving = true;
    this.currentIndex += step;
    // if (this.currentIndex <)
    // this.currentIndex = Math.min(this.bannerList.length, this.currentIndex + step)
    clearTimeout(this.movingEndTimer);
    this.movingEndTimer = setTimeout(() => {
      this.isMoving = false;
      if (this.currentIndex === this.imgs.length) {
        this.currentIndex = 0;
      }
    }, this.delayTime);
  }

  public tryFree () {
    console.log('adsasda');
  }
}
