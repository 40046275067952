
































































































































































































import { Component, Vue } from 'vue-property-decorator';
import HulaTry from '@/components/hula-try/index.vue';
import HulaFixBanner from '@/components/hula-fix-banner/index.vue';

@Component({
  name: 'Charge',
  components: { HulaFixBanner, HulaTry }
})
export default class Charge extends Vue {
  public tabFixed = false
  public introduceHeight: number[] = []
  public currentIndex = 0
  public tabList = [
    {
      name: '收费与查询'
    },
    {
      name: '对账报表'
    },
    {
      name: '预存款充值'
    },
    {
      name: '赠送/减免/限时折扣'
    },
    {
      name: '日志管理'
    }
  ]

  public mounted () {
    this.$bus.$on('scroll', this.getBannerHeight);
  }

  public beforeDestroy () {
    this.$bus.$rm('scroll', this.getBannerHeight);
  }

  public getHeight () {
    const uls = document.querySelectorAll<HTMLDivElement>('.hula-introduce .charge');
    this.introduceHeight = Array.from(uls).map((ul) => this.windowWidth >= 700 ? ul.offsetTop - 56 : ul.offsetTop - 62);
  }

  public getElementsScroll () {
    // const element = document.querySelector<HTMLDivElement>('.hula-tab');
    const scrollElement = document.querySelector<HTMLDivElement>('.hula-tab-wrapper');
    const items = document.querySelectorAll<HTMLDivElement>('.hula-tab-item');
    const rightDt = items[this.currentIndex].offsetLeft + items[this.currentIndex].getBoundingClientRect().width;
    if (rightDt - (scrollElement!.scrollLeft + scrollElement!.getBoundingClientRect().width) > 0) {
      // console.log('进来了', scrollElement!.scrollLeft);
      scrollElement!.scrollLeft = rightDt - scrollElement!.getBoundingClientRect().width + 10;
    }
    const delta = scrollElement!.scrollLeft - items[this.currentIndex].offsetLeft;
    if (delta > 0) {
      scrollElement!.scrollLeft -= delta + 20;
    }
  }

  public getBannerHeight () {
    this.getHeight();
    this.getElementsScroll();
    const div = document.querySelector('.layouts--main');
    if (div) {
      this.tabFixed = this.windowWidth >= 700 ? div.scrollTop >= 830 : div.scrollTop >= 1013;
      if (div.scrollTop < this.introduceHeight[0]) {
        this.currentIndex = 0;
        return;
      }
      const index = this.introduceHeight.findIndex(it => it > div.scrollTop);
      this.currentIndex = index < 0 ? this.introduceHeight.length - 1 : index - 1;
    }
  }

  public getItemTop (index: number) {
    this.getHeight();
    this.getElementsScroll();
    const div = document.querySelector('.layouts--main');
    this.currentIndex = index;
    if (div) {
      div.scrollTop = this.introduceHeight[index];
    }
  }
}
