



















































































































































import { Component, Vue } from 'vue-property-decorator';
import Banner from '@/pages/home/components/banner.vue';
import SvgIcon from '@/components/svg-icon/svg-icon.vue';
@Component({
  name: 'Home',
  components: { Banner, SvgIcon }
})
export default class Home extends Vue {
  private overIndex = 0
  private tabData = [
    {
      title: '户乐物管',
      subTitle: '助力打破传统物业经营困境'
    },
    {
      title: '户乐社区',
      subTitle: '让业主更省心，让住户更满意'
    }
  ];

  public handleMouseOver (e: any, index: number) {
    this.overIndex = index;
  }
}
