



























































import { Component, Vue } from 'vue-property-decorator';
import HulaTry from '@/components/hula-try/index.vue';
import HulaFixBanner from '@/components/hula-fix-banner/index.vue';

@Component({
  name: 'Boss',
  components: { HulaFixBanner, HulaTry }
})
export default class Boss extends Vue {
}
