import Vue from 'vue';
import SvgIcon from '../components/svg-icon/index';

// console.log(SvgIcon);
Vue.use(SvgIcon);
// register globally
// Vue.component('svg-icon', SvgIcon);
const requireAll = (requireContext: any) => requireContext.keys().map(requireContext);
const req = require.context('@/assets/svg', false, /\.svg$/);
// console.log(req.keys());
requireAll(req);
