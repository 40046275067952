<!-- Author: keli.yu@accentrix.com -->
<!-- Date: 2020/4/8 17:18 -->
<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName"
         v-if="fillColor"
         :style="{ fill: fillColor }"></use>
    <use :xlink:href="iconName" v-else></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String
    },
    className: {
      type: String
    },
    fillColor: {
      type: String
    }
  },
  computed: {
    iconName () {
      return `#icon-${this.iconClass}`;
    },
    svgClass () {
      if (this.className) {
        return 'svg-icon ' + this.className;
      } else {
        return 'svg-icon';
      }
    }
  }
};
</script>

<style lang="scss">
.svg-icon {
  width: 1em;
  height: 1em;
  font-size: 14px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
