export interface IEventBus {
  $on(type: string, callback: (payload?: any) => any): void;
  $emit(type: string, payload: any): void;
  $rm(type: string, callback: (payload?: any) => any): void;
}

class EventBus implements IEventBus {
  private _events: {[param: string]: Array<(payload?: any) => any>} = {}

  public $on (type: string, callback: (payload?: any) => any): void {
    if (!this._events[type]) {
      this._events[type] = [];
    }
    this._events[type].push(callback);
  }

  $emit (type: string, payload: any): void {
    if (this._events[type]) {
      this._events[type].forEach(callback => callback(payload));
    }
  }

  $rm (type: string, callback: (payload?: any) => any): void {
    if (this._events[type]) {
      const index = this._events[type].findIndex(cb => cb === callback);
      if (index >= 0) {
        this._events[type].splice(index, 1);
      }
    }
  }
}

export default EventBus;
