

































































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'HulaFixBanner'
})
export default class Index extends Vue {
  get routeName () {
    return this.$route.name;
  }
}
