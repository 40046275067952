
























































































































































































































































































































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import svgIcon from '../svg-icon/svg-icon.vue';
import {showToast} from '@/utils/dom.util';

@Component({
  components: { svgIcon },
  name: 'Layouts'
})
export default class Layouts extends Vue {
  @Ref('scrollBody')
  private $scrollBody?: HTMLDivElement;

  private routeData = [
    'Boss',
    'SmartMg',
    'CustomerService',
    'Charge',
    'Engineering',
    'Safety',
    'Assistant',
    'Property'
  ];

  private menuList = [
    {
      label: '首页',
      isChildren: false,
      isSelected: false,
      isExpand: false,
      routeName: 'Home'
    },
    {
      label: '户乐生态',
      isChildren: false,
      isSelected: false,
      isExpand: true,
      routeName: 'Boss'
    },
    {
      label: '最新资讯',
      isChildren: false,
      isSelected: false,
      isExpand: false,
      routeName: 'SmartMg'
    },
    {
      label: '关于我们',
      isChildren: false,
      isSelected: false,
      isExpand: false,
      routeName: 'CustomerService'
    }
  ];

  // private menuList = [
  //   {
  //     label: '户乐物管',
  //     isChildren: false,
  //     isSelected: false,
  //     isExpand: true,
  //     routeName: 'Property'
  //   },
  //   {
  //     label: '智慧总裁',
  //     isChildren: true,
  //     isSelected: false,
  //     isExpand: false,
  //     routeName: 'Boss'
  //   },
  //   {
  //     label: '智慧管理',
  //     isChildren: true,
  //     isSelected: false,
  //     isExpand: false,
  //     routeName: 'SmartMg'
  //   },
  //   {
  //     label: '智能客服',
  //     isChildren: true,
  //     isSelected: false,
  //     isExpand: false,
  //     routeName: 'CustomerService'
  //   },
  //   {
  //     label: '智能收费',
  //     isChildren: true,
  //     isSelected: false,
  //     isExpand: false,
  //     routeName: 'Charge'
  //   },
  //   {
  //     label: '智能工程',
  //     isChildren: true,
  //     isSelected: false,
  //     isExpand: false,
  //     routeName: 'Engineering'
  //   },
  //   {
  //     label: '智能安管',
  //     isChildren: true,
  //     isSelected: false,
  //     isExpand: false,
  //     routeName: 'Safety'
  //   },
  //   {
  //     label: '智能OA',
  //     isChildren: true,
  //     isSelected: false,
  //     isExpand: false,
  //     routeName: ''
  //   },
  //   {
  //     label: '办公助手',
  //     isChildren: true,
  //     isSelected: false,
  //     isExpand: false,
  //     routeName: 'Assistant'
  //   },
  //   {
  //     label: '户乐社区',
  //     isChildren: false,
  //     isSelected: false,
  //     isExpand: true,
  //     routeName: 'Community'
  //   },
  //   // {
  //   //   label: "入驻加盟",
  //   //   isChildren: false,
  //   //   isSelected: false,
  //   //   isExpand: true,
  //   //   routeName: "",
  //   // },
  //   {
  //     label: '商家入驻',
  //     isChildren: true,
  //     isSelected: false,
  //     isExpand: false,
  //     routeName: ''
  //   },
  //   {
  //     label: '房中介入驻',
  //     isChildren: true,
  //     isSelected: false,
  //     isExpand: false,
  //     routeName: ''
  //   },
  //   {
  //     label: '销售加盟',
  //     isChildren: true,
  //     isSelected: false,
  //     isExpand: false,
  //     routeName: ''
  //   }
  //   // {
  //   //   label: "价格",
  //   //   isChildren: false,
  //   //   isSelected: false,
  //   //   isExpand: true,
  //   //   routeName: "",
  //   // },
  // ];

  private isOpened = false;
  private QrcodeOpened = false;
  private selectedLabel = '';
  private isFold = false
  get routeName () {
    const name = this.$route.name;
    if (name) {
      return name === 'Property';
      // return this.routeData.includes(name.toString());
    }
  }

  public get isCurrentP () {
    const name = this.$route.name;
    if (name) {
      return this.routeData.includes(name);
    }
  }

  public menuClass (item: any) {
    return {
      'is-selected': this.$route.name === item.routeName
      // 'is-children': item.isChildren
    };
  }

  public get showMenu () {
    return {
      'margin-left': this.isOpened ? '0' : '-275px'
    };
  }

  /**
   * currentRoute
   */
  get currentRoute () {
    console.log('currentRoute', this.$route.name);

    return this.$route.name;
  }

  @Watch('$route')
  handleRouteChange () {
    if (this.$scrollBody) {
      this.$scrollBody.scrollTop = 0;
    }
  }

  public mounted () {
    this.selectedLabel = '';
    if (this.$scrollBody) {
      this.$scrollBody.addEventListener('scroll', this.handleScroll);
    }
  }

  public beforeDestroy () {
    if (this.$scrollBody) {
      this.$scrollBody.removeEventListener('scroll', this.handleScroll);
    }
  }

  public handleMove (event: any) {
    console.log(event);
    const w =
      event.changedTouches[0].screenX < 0
        ? event.changedTouches[0].screenX * -1
        : event.changedTouches[0].screenX < 0;
    const h =
      event.changedTouches[0].screenY < 0
        ? event.changedTouches[0].screenY * -1
        : event.changedTouches[0].screenY < 0;
    if (w > h) {
      // 如果是在x轴中滑动
      event.stop.prevent();
    }
  }

  public gotoRoute (name: string) {
    this.isOpened = false;
    this.menuList.forEach((it) => {
      it.isSelected = false;
      if (it.isChildren) {
        it.isExpand = false;
      }
    });
    if (this.$route.name !== name) {
      this.$router.push({
        name
      });
    }
  }

  private handleScroll (e: any) {
    // if (!this.timer) {
    this.$bus.$emit('scroll', e);
    // this.timer = setTimeout(() => {
    //   this.timer = undefined;
    // }, 100);
    // }
  }

  // private selectLabel (item: any, index: number) {
  // this.menuList[index].isSelected = !this.menuList[index].isSelected;
  // if (item.isChildren) {
  //   this.isOpened = false;
  //   this.menuList.forEach((it) => {
  //     it.isSelected = false;
  //     if (it.isChildren) {
  //       it.isExpand = false;
  //     }
  //   });
  // }
  // if (item.label === '户乐社区') {
  //   this.isOpened = false;
  // }
  // this.menuList.forEach(() => {
  //   if (index === 0) {
  //     for (let s = 1; s < 9; s++) {
  //       this.menuList[s].isExpand = !this.menuList[s].isExpand;
  //     }
  //   } else if (index === 10) {
  //     for (let k = 11; k < 14; k++) {
  //       this.menuList[k].isExpand = !this.menuList[k].isExpand;
  //     }
  //   }
  // });
  // this.selectedLabel = item.label === this.selectedLabel ? '' : item.label;
  // }

  private menuFoldClick () {
    this.isFold = !this.isFold;
    console.log('menuFoldClick');
  }

  private phoneClick () {
    this.isOpened = !this.isOpened;
  }

  private menuClick () {
    this.isOpened = !this.isOpened;
    this.menuList.forEach((it) => {
      it.isSelected = false;
      if (it.isChildren) {
        it.isExpand = false;
      }
    });
    this.selectedLabel = '';
  }

  private handleToast(){
    showToast('敬请期待');
    console.log('handleToast');
  }
}
