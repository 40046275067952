





import { Component, Vue } from 'vue-property-decorator';
@Component({
  name: 'APP'
})
export default class APP extends Vue {
  public mounted () {
    window.addEventListener('resize', this.getWidth);
  }

  private getWidth (e: any) {
    console.log('%c█ getWidth() -> e:\n', 'color: #3ba776',
      e
    );
    this.$bus.$emit('resize', e);
  }
}
