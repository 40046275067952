// 显示toast 简单的toast框，若有复杂需求考虑按需引入UI框架的toast
// hula-toast 的样式在 common.scss
export const showToast = (title: string | number) => {
  // 防止重复触发
  const isHasToastDom = document.body.querySelector('#hula-toast');
  if (!title || isHasToastDom) {
    return;
  }
  const dom = document.createElement("div");
  dom.className = 'hula-toast';
  dom.id = 'hula-toast';
  dom.innerHTML = String(title);
  document.body.appendChild(dom);
  setTimeout(() => {
    document.body.removeChild(dom);
  }, 1000)
}
