









































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Banner from '@/pages/property/components/banner.vue';
import HulaTry from '@/components/hula-try/index.vue';
import SvgIcon from '@/components/svg-icon/svg-icon.vue';

@Component({
  name: 'Property',
  components: { HulaTry, Banner, SvgIcon }
})
export default class Property extends Vue {
  private showTryFree = false
  private tabTwo = [
    {
      label: '行业优势'
    },
    {
      label: '痛点方案'
    }
  ]

  private newTabIndex = 0
  private tabTwoIndex = 0
  private left = 0
  private showAll = false
  public tabFixed = false
  private seeWidth = 0
  public created () {
    this.$bus.$on('scroll', this.getBannerHeight);
    this.scrollToTop();
  }

  public mounted () {
    this.getTabPosition(0);
  }

  get position () {
    return {
      left: this.left + 'px'
    };
  }

  public get seePosition () {
    return {
      transform: `translateX(${this.seeWidth}px)`
    };
  }

  /**
   * newTabClick
   */
  public newTabClick (index: number) {
    this.newTabIndex = index;
    this.getTabPosition(index);
    this.$nextTick(() => {
      const div = document.querySelectorAll<HTMLDivElement>('.new-tab-detail .see-all');
      if (div && div.length > 0) {
        this.seeWidth = -div[0].getBoundingClientRect().width / 2;
        console.log(this.seeWidth);
      }
    });
  }

  /**
   * handleSeeall
   */
  public handleSeeAll () {
    this.showAll = true;
  }

  /**
   * getTabPosition
   */
  public getTabPosition (index: number) {
    const div = document.querySelectorAll('.new-tab li');
    const x = div[index].getBoundingClientRect().x;
    const width = div[index].getBoundingClientRect().width;
    const textWidth = (width - 104) / 2;
    this.left = x + 36 + textWidth;
  }

  public getBannerHeight () {
    const div = document.querySelector('.layouts--main');
    this.showTryFree = div!.scrollTop >= 350;
    if (this.showAll) {
      this.tabFixed = div!.scrollTop >= 990;
    } else {
      this.tabFixed = div!.scrollTop >= 678;
    }
  }

  public scrollToTop () {
    const div = document.querySelector('.layouts--main');
    if (div) div.scrollTop = 0;
  }

  public gotoRoute (name: string) {
    if (this.$route.name !== name) {
      this.$router.push({
        name: name
      });
    }
  }

  public tryFree () {
    console.log('adsasda');
  }
}
