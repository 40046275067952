import Vue from 'vue';
import App from './App.vue';
import './theme/index.scss';
import './registerServiceWorker';
import router from './router';
import './theme';
import EventBus from '@/utils/event-bus';

Vue.config.productionTip = false;
Vue.prototype.$bus = new EventBus();
Vue.prototype.windowWidth = window.innerWidth;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
