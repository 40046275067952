
import { RouteConfig } from 'vue-router';
// @ts-ignore
import Layouts from '@/components/layouts/index';
import Home from '@/pages/home/home.vue';
import Property from '@/pages/property/index.vue';
import SmartMg from '@/pages/smart-mg/index.vue';
import CustomerService from '@/pages/customer-service/index.vue';
import Safety from '@/pages/safety/index.vue';
import Engineering from '@/pages/engineering/index.vue';
import Boss from '@/pages/boss/index.vue';
import Charge from '@/pages/charge/index.vue';
import Community from '@/pages/hula/index.vue';
import Assistant from '@/pages/assistant/index.vue';
const routes: Array<RouteConfig> = /*routes-start*/[
  {
    path: '/',
    name: 'Layouts',
    component: Layouts,
    redirect: {
      name: 'Home'
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'property',
        name: 'Property',
        component: Property
      },
      {
        path: 'smart-mg',
        name: 'SmartMg',
        component: SmartMg
      },
      {
        path: 'cs',
        name: 'CustomerService',
        component: CustomerService
      },
      {
        path: 'safety',
        name: 'Safety',
        component: Safety
      },
      {
        path: 'engineering',
        name: 'Engineering',
        component: Engineering
      },
      {
        path: 'boss',
        name: 'Boss',
        component: Boss
      },
      {
        path: 'charge',
        name: 'Charge',
        component: Charge
      },
      {
        path: 'community',
        name: 'Community',
        component: Community
      },
      {
        path: 'assistant',
        name: 'Assistant',
        component: Assistant
      }
    ]
  }
]/*routes-end*/;

export default routes;
